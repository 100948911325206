import * as React from "react"
import Card from "./Card"

const Carousel = ({ siteTitle }) => (
    <div className="relative">
        <div className="w-full">
        <ul className="overflow-x-scroll overflow-y-hidden whitespace-nowrap ml-0 pl-0">
       <Card />
       <Card />
       <Card />
       <Card />
       <Card />
       <Card />
       <Card />
       <Card />
       </ul>
    </div>
    </div>

)
export default Carousel

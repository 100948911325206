import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/Carousel/Card"
import Carousel from "../components/Carousel"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Carousel />
  </Layout>
)

export default IndexPage

import * as React from "react"
import Image1 from "../../../images/main-1.png"
const Card = ({ siteTitle }) => (
    <li style={{ width: '250px' }} className="inline-block bg-white mr-3 shadow-md hover:shadow-md rounded-lg  rounded-lg border-gray-100 border-2">
        <div className="rounded-t-lg" style={{ width: '250px', height: '200px', background: `url(${Image1}) no-repeat center center` }}>
            &nbsp;</div>
        <div className="bg-white rounded-b-lg p-2 bg-gray-100">
            <span className="text-xl font-bold block">KatieXFans</span>
            <div className="text-sm inline-block pt-2 whitespace-normal	">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vehicula auctor sem ac egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <div className="pt-4">
                    <span className=" bg-white mr-3 py-1 px-2 shadow-sm rounded-lg border-gray-100 border-2">porn</span>
                    <span className=" bg-white mr-3 py-1 px-2 shadow-sm rounded-lg border-gray-100 border-2">naked</span>
                </div>
                </div>
        </div>
    </li>

)
export default Card
